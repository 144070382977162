import ProductList from './productlist.js'
import CriteriaForm from './criteriaform';
import { useState } from "react";
import productData from "./data/products.json"

export default function RecommandationFlow() {

    const [viewToDisplay, setViewToDisplay] = useState("form");
    const [filteredProducts, setFilteredProducts] = useState(productData.products);
    const [usedFilters, setUsedFilters] = useState({"surface": "", "sol": "", "salissures": "", "desinfection": "", "ecoproduct": ""});

    const frenchToBool = {
      "Oui": true,
      "Non": false
    }

    const handleGoBackBis = () => {
      setViewToDisplay("form");
    }

    const onClick = (submittedCriterias) => {
        setUsedFilters({"surface": submittedCriterias.surface, "sol": submittedCriterias.sol, "salissures": submittedCriterias.salissures, "desinfection": submittedCriterias.desinfection, "ecoproduct": submittedCriterias.ecoproduct});
        console.log("Form has been submitted with: " + submittedCriterias.surface + ', ' + submittedCriterias.sol + ', ' + submittedCriterias.salissures + ', ' + submittedCriterias.desinfection + ', ' + submittedCriterias.ecoproduct);
        // SHould be adjusted to include ecocert et other
        let tempFiltered = productData.products
        if (submittedCriterias.surface){
          console.log("surface is not empty, filtering");
          console.log(submittedCriterias.surface);
          tempFiltered = tempFiltered.filter(product => product.typeDeSurface.includes(submittedCriterias.surface));
        }
        if (submittedCriterias.sol){
          console.log("sol is not empty, filtering");
          console.log(submittedCriterias.sol);
          //typeDeSol is a bit confusing but it actually does the job for sol, hors-sols, sanitaires. We should prob rename it to sous-surface or something.
          tempFiltered = tempFiltered.filter(product => product.typeDeSol.includes(submittedCriterias.sol));
        }
        if (submittedCriterias.salissures){
          console.log("salissures is not empty, filtering");
          console.log(submittedCriterias.salissures);
          tempFiltered = tempFiltered.filter(product => product.typeDeSalissures.includes(submittedCriterias.salissures));
        }
        if (["Oui", "Non"].includes(submittedCriterias.desinfection)){
          console.log("desinfection is not empty, filtering");
          console.log(submittedCriterias.desinfection)
          tempFiltered = tempFiltered.filter(product => product.desinfectant === frenchToBool[submittedCriterias.desinfection]);
        }
        if (submittedCriterias.ecoproduct){
          console.log("ecoproduct is not empty, filtering");
          console.log(submittedCriterias.ecoproduct)
          tempFiltered = tempFiltered.filter(product => product.ecocert || product.ecolabel)
        }
        setFilteredProducts(tempFiltered)
        setViewToDisplay("list");
      }

    

    const recoStyles = {
      main: {
        width: "100%"
      }
    }

    return (
      <div style={recoStyles.main}>
            {viewToDisplay === "form" && <CriteriaForm update={onClick} pastSubmittedCriteria={usedFilters} /> }
            {viewToDisplay === "list" && <ProductList fakeProductData={filteredProducts} propOnClickBis={handleGoBackBis}/> }
      </div>
    );
  }
  
