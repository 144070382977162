import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import formChoices from "./data/formchoices.json"

const besoinDeDesinfection = ["Oui", "Non", "Peu importe"];

export default function CriteriaForm({ update, pastSubmittedCriteria }) {
  const [surface, setSurface] = useState(pastSubmittedCriteria.surface);
  const [sol, setSol] = useState(pastSubmittedCriteria.sol);
  const [salissures, setSalissures] = useState(pastSubmittedCriteria.salissures);
  const [desinfection, setDesinfection] = useState(pastSubmittedCriteria.desinfection);
  const [ecoproduct, setEcoproduct] = useState(pastSubmittedCriteria.ecoproduct);
  const [typeDeSolv, setTypeDeSolv] = useState([]);
  const [showTypeDeSol, setShowTypeDeSol] = useState(false);
  const [showTypeDeSalissures, setShowTypeDeSalissures] = useState(false);
  const [showDesinfection, setShowDesinfection] = useState(false);
  const [showEcoproduct, setShowEcoproduct] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const handleChangeSurface = ({ target }) => {
    setSurface(target.value);
  };

  useEffect(() => {
    if (surface.toLowerCase() === "sanitaires") {
      setTypeDeSolv(formChoices.typeDeSols.sanitaires);
      setShowTypeDeSol(true);
    }
    else if (surface.toLowerCase() === "hors-sols") {
      setTypeDeSolv(formChoices.typeDeSols.horsSols);
      setShowTypeDeSol(true);
    }
    else if (surface.toLowerCase() === "sols") {
      setTypeDeSolv(formChoices.typeDeSols.sols);
      setShowTypeDeSol(true);
    }
    else {
      setShowTypeDeSol(false);
    }
  }, [surface])

  const handleChangeSol = ({ target }) => {
    setSol(target.value);
  };

  useEffect(() => {
    if (sol !== "") {
      setShowTypeDeSalissures(true);
    }
    else {
      setShowTypeDeSalissures(false);
    }
  }, [sol])

  const handleChangeSalissures = ({ target }) => {
    setSalissures(target.value);
  };

  useEffect(() => {
    if (salissures !== "") {
      setShowDesinfection(true);
    }
    else {
      setShowDesinfection(false);
    }
  }, [salissures])

  const handleChangeDesinfection = ({ target }) => {
    setDesinfection(target.value);
  };

  useEffect(() => {
    if (desinfection !== "") {
      setShowEcoproduct(true);
    }
    else {
      setShowEcoproduct(false);
    }
  }, [desinfection])

  const handleChangeEcoproduct = (event) => {
    setEcoproduct(event.target.checked);
  };

  const criterias = {
    surface: surface,
    sol: sol,
    salissures: salissures,
    desinfection: desinfection,
    ecoproduct: ecoproduct
  };

  const handleClick = () => {
    update(criterias);
  }

  const styles = {
    main: {
      display: 'flex',
      flexDirection: 'column',
    },
    text: {
      textAlign: 'left',
    },
    toggleButtondiv: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: "25px"
    },
    finalButton: {
      marginTop: "25px"
    }
  }

  return (
    <div style={styles.main}>
      <Box>
        <FormControl fullWidth variant="filled">
          <p style={styles.text}>Type de surface</p>
          <Select
            displayEmpty
            value={surface}
            onChange={handleChangeSurface}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>Sélectionner</em>;
              }
              return selected;
            }}
            sx={{ minWidth: "100%" }}
          >
            {formChoices.typeDeSurfaces.map((surfacetype) => (
              <MenuItem
                value={surfacetype}
                key={surfacetype}>
                {surfacetype}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {showTypeDeSol && <FormControl fullWidth variant="filled">
          <p style={styles.text}>Type de {surface.toLowerCase()}</p>
          <Select
            displayEmpty
            value={sol}
            onChange={handleChangeSol}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>Sélectionner</em>;
              }
              return selected;
            }}
          >
            {typeDeSolv.map((soltype) => (
              <MenuItem value={soltype} 
                        key={soltype}>
                {soltype}
              </MenuItem>
            ))}
          </Select>
        </FormControl>}
 
        {showTypeDeSalissures && <FormControl fullWidth variant="filled">
          <p style={styles.text}>Type de salissures</p>
          <Select
            displayEmpty
            value={salissures}
            onChange={handleChangeSalissures}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>Sélectionner</em>;
              }
              return selected;
            }}
          >
            {formChoices.typeDeSalissures.map((salissurestype) => (
              <MenuItem value={salissurestype} 
                        key={salissurestype}>
                {salissurestype}
              </MenuItem>
            ))}
          </Select>
        </FormControl>}

        {showDesinfection && <FormControl fullWidth variant="filled">
          <p style={styles.text}>Besoin de désinfection</p>

          <Select
            displayEmpty
            value={desinfection}
            onChange={handleChangeDesinfection}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>Sélectionner</em>;
              }
              return selected;
            }}
          >
            {besoinDeDesinfection.map((desinfectionneed) => (
              <MenuItem value={desinfectionneed} key={desinfectionneed}>{desinfectionneed}</MenuItem>
            ))}
          </Select>
        </FormControl>}
      </Box>

      {showEcoproduct && <div>
        <div style={styles.toggleButtondiv}>
          <div>
            Produit Ecolabel/Ecocert
          </div>
          <FormGroup>
            <FormControlLabel
              control={<Switch />}
              checked={ecoproduct}
              onChange={handleChangeEcoproduct}
            />
          </FormGroup>
        </div>

        <Button color="primary" variant="contained" size="large" style={styles.finalButton} onClick={handleClick}>Obtenir une recommandation</Button>
      </div>}
    </div>
  );
}

