import left_arrow_icon from './icons8-left-arrow-50.png';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';

export default function ProductDetails({selectedProduct, propOnClick}) {

    const handleClick = () => {
        propOnClick();
    }

    const styles = {
        goBackToMenu: {
            display: "flex",
            flexDirection: "row"
        },
        leftArrowIcon: {
            marginRight: "12px"
        },
        card: {
            backgroundColor: "#f3f3f3ff",
            borderRadius: "10px",
            marginBottom: "20px"
        },
        title: {
            textTransform: "uppercase",
            paddingTop: "34px",
            marginTop: "0px"
        },
        image: {
            maxWidth: "250px"
        },
        categoryNameWithoutTooltip: {
            textTransform: "uppercase",
            fontWeight: "bold"
        },
        categoryNameWithTooltip: {
            textTransform: "uppercase",
            fontWeight: "bold",
            display: "flex",
            flexDirection: "row"
        }
    }


    return (
        <div>
            <div onClick={handleClick} style={styles.goBackToMenu}>
                <img src={left_arrow_icon} style={styles.leftArrowIcon} alt='Flèche Gauche Retour au Menu'/>
                <p>Retour à mes recommandations</p>
            </div>
            <div style={styles.card}>
                <h3 style={styles.title}>{selectedProduct.name}</h3>
                <img src={selectedProduct.imageUrl} alt={selectedProduct.name} style={styles.image}/>

                <TableContainer>
                    <Table>
                            <TableRow>                        
                                <TableCell style={styles.categoryNameWithoutTooltip}>Désignation</TableCell>
                                <TableCell>{selectedProduct.designation}</TableCell>
                            </TableRow>
                            <TableRow> 
                                <TableCell style={styles.categoryNameWithoutTooltip}>Description</TableCell>
                                <TableCell>{selectedProduct.description}</TableCell>
                            </TableRow>
                            <TableRow> 
                                <TableCell style={styles.categoryNameWithoutTooltip}>pH</TableCell>
                                <TableCell>{selectedProduct.ph}</TableCell>
                            </TableRow>

                            {selectedProduct.desinfectant && <TableRow> 
                                <TableCell style={styles.categoryNameWithoutTooltip}>Produit de désinfection</TableCell>
                                <TableCell>Oui</TableCell>
                            </TableRow>}
                            {!selectedProduct.desinfectant && <TableRow> 
                                <TableCell style={styles.categoryNameWithoutTooltip}>Produit de désinfection</TableCell>
                                <TableCell>Non</TableCell>
                            </TableRow>}

                            {selectedProduct.ecolabel && <TableRow> 
                                <TableCell style={styles.categoryNameWithoutTooltip}>
                                    Produit Ecolabel/Ecocert 
                                </TableCell>
                                <TableCell>Ecolabel</TableCell>
                            </TableRow>}
                            {selectedProduct.ecocert && <TableRow> 
                                <TableCell style={styles.categoryNameWithoutTooltip}>
                                    Produit Ecolabel/Ecocert 
                                </TableCell>
                                <TableCell>Ecocert</TableCell>
                            </TableRow>}
                            {/* Below syntax seems to be causing the extra 0 to show up in the middle of the table*/}
                            {(!selectedProduct.ecolabel && !selectedProduct.ecocert) && <TableRow> 
                                <TableCell style={styles.categoryNameWithoutTooltip}>
                                    Produit Ecolabel/Ecocert 
                                </TableCell>
                                <TableCell>Non</TableCell>
                            </TableRow>}

                            {selectedProduct.safe && <TableRow> 
                                <TableCell style={styles.categoryNameWithTooltip}>
                                    Produit Safe/Safe+ 
                                    <Tooltip title="Les produits SAFE sont non étiquetés, sans CMR et sans étiquetage TMD. Les produits SAFE+ présentent les mêmes avantages que les produits SAFE et sont, en plus, sans VLEP." enterTouchDelay={0} arrow followCursor>
                                        <IconButton>
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>Safe</TableCell>
                            </TableRow>}
                            {selectedProduct.safePlus && <TableRow> 
                                <TableCell style={styles.categoryNameWithTooltip}>Produit Safe/Safe+
                                    <Tooltip title="Les produits SAFE sont non étiquetés, sans CMR et sans étiquetage TMD. Les produits SAFE+ présentent les mêmes avantages que les produits SAFE et sont, en plus, sans VLEP." enterTouchDelay={0} arrow followCursor>
                                        <IconButton>
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>Safe+</TableCell>
                            </TableRow>}
                            {/* Below syntax seems to be causing the extra 0 to show up in the middle of the table*/}
                            {(!selectedProduct.safe && !selectedProduct.safePlus) && <TableRow> 
                                <TableCell style={styles.categoryNameWithTooltip}>Produit Safe/Safe+
                                    <Tooltip title="Les produits SAFE sont non étiquetés, sans CMR et sans étiquetage TMD. Les produits SAFE+ présentent les mêmes avantages que les produits SAFE et sont, en plus, sans VLEP." enterTouchDelay={0} arrow followCursor>
                                        <IconButton>
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>Non</TableCell>
                            </TableRow>}

                            <TableRow> 
                                <TableCell style={styles.categoryNameWithoutTooltip}>Parfum</TableCell>
                                <TableCell>{selectedProduct.parfum}</TableCell>
                            </TableRow>
                            <TableRow> 
                                <TableCell style={styles.categoryNameWithoutTooltip}>Type de produit</TableCell>
                                <TableCell>{selectedProduct.type_produit}</TableCell>
                            </TableRow>
                            <TableRow> 
                                <TableCell style={styles.categoryNameWithoutTooltip}>Taux de dilution</TableCell>
                                <TableCell>{selectedProduct.taux_dilution}</TableCell>
                            </TableRow>
                            <TableRow> 
                                <TableCell style={styles.categoryNameWithoutTooltip}>Mode d'utilisation</TableCell>
                                <TableCell>{selectedProduct.mode_utilisation}</TableCell>
                            </TableRow>
                            <TableRow> 
                                <TableCell style={styles.categoryNameWithoutTooltip}>Références du produit</TableCell>
                                <TableCell>{selectedProduct.codes_produit}</TableCell>
                            </TableRow>
                            <TableRow> 
                                <TableCell style={styles.categoryNameWithoutTooltip}>Fiche technique</TableCell>
                                <TableCell>
                                    <Link href={selectedProduct.ft_url} underline="always">
                                        {'Disponible ici'}
                                    </Link>
                                </TableCell>
                            </TableRow>
                    </Table>
                </TableContainer>

            </div>
        </div>
    )
}