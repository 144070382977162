import './App.css';
import React from 'react';
import HomepageLogo from './homepage_logo';

function App() {
  const appStyles = {
    main: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      //alignItems: "center",
      textAlign: "center",
      fontFamily: 'sans-serif',
      color: '#171c8f',
      fontSize: "calc(10px + 2vmin)"
    }
  }

  return (
    <div style={appStyles.main}>
        <HomepageLogo />
    </div>
  );
}

export default App;
