import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';


export default function Header({ propOnClick }) {
    const goBack = () => {
        propOnClick();
    }

    return (

        <Box>
            <AppBar position="static">
                <Toolbar>
                    <IconButton align='left' onClick={goBack}>
                        <Avatar alt="Logo Laboratoires Rochex Menu"
                                src="https://www.laboratoires-rochex.com/wp-content/uploads/favicon-rochex.png"
                                //sx={{ width: 46, height: 48 }}
                        />
                    </IconButton>
                    <Typography variant="h6" align='left'>
                        RochExpert
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>

    );
}

