import { useState } from "react";
import Button from "@mui/material/Button";
import RecommandationFlow from './recommandation_flow'
import Header from "./header";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#eeeeeeff",
                    display: "flex",
                    justifyContent: "flex-start"
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#171c8f'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#171c8f',
                    textTransform: 'none',
                    fontFamily: 'sans-serif',
                    '&:hover': {
                        backgroundColor: '#04b4e4ff'
                    }
                },
            },
        },
        MuiInputBase: { 
            styleOverrides: {
                root: {
                    backgroundColor: "#eeeeeeff",
                    color: "#171c8f"
                }
            }
        },
        MuiPaper: { // component that contains the list. Pourquoi utiliser MuiPaper et pas MuiList ? Les deux ont l’air de fonctionner.
            styleOverrides: {
                root: {
                    backgroundColor: "#eeeeeeff",
                    color: "#171c8f"
                }
            }
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    textAlign: 'right'
                }
            }
        },
        // Mouch told me I should put this in the component that needs it but did not manage to get it working (because ThemeProvider is used in here?)
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: "#171c8f",
                }
            }
        }
    },
});

export default function Homepage() {

    const styles = {
        content: {
            display: "flex",
            justifyContent: "center",
            marginLeft: "2%",
            marginRight: "2%",
        },
        buttonsDiv: {
            maxWidth: "700px",
            paddingTop: "20px"
        },
        followingDivs: {
            maxWidth: "700px",
        },
        enabledParagraph: {
            fontSize: "15px"
        },
        disabledParagraph: {
            fontSize: "15px",
            color: "#a6a6a6"
        }
    }

    const [viewToDisplay, setViewToDisplay] = useState("homepage");

    const goToNext = () => {
        setViewToDisplay("form");
    }

    const goBackToHomepage = () => {
        setViewToDisplay("homepage");
    }

    return (
        <div>
             <ThemeProvider theme={theme}>
                <Header propOnClick={goBackToHomepage}/>
                <div style={styles.content}>
                {(() => {
            if (viewToDisplay === "homepage") {
                return (
                <div style={styles.buttonsDiv}>
                    <Button variant="contained" size="large" onClick={goToNext}>Obtenir une recommandation</Button>
                    <p style={styles.enabledParagraph}>Obtenez une recommandation personnalisée pour connaître le produit Rochex convenant le mieux aux besoins de votre zone.</p>
                    {/* <Button disabled={true} variant="contained" size="large">Démarrer une inspection (bientôt disponible)</Button>
                    <p style={styles.disabledParagraph}>Créez la liste de produits Rochex à commander pour chacun de vos sites et zones. Envoyez cette liste à l’adresse mail de votre choix.</p> */}
                </div>
                );
              } else {
                return (
                    <div style={styles.followingDivs}>
                        <RecommandationFlow />
                    </div>
                    );
              }})()}
              </div>
             </ThemeProvider>
        </div>
    )

}

