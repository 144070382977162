import { useEffect, useState } from "react";
import Homepage from "./homepage";
import logo from './rocheXpert_logo.png';

export default function HomepageLogo() {
    const [viewToDisplay, setViewToDisplay] = useState("homepage_logo");

    useEffect(() => {
        const timer = setTimeout(() => {
            setViewToDisplay("homepage");
        }, 2000);
        return () => clearTimeout(timer);
      }, []);

    const homepageDisappears = () => {
        setViewToDisplay("homepage");
    }

    const styles = {
        content: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
        }
    }

    if (viewToDisplay === "homepage_logo") {
            return (
        <div style={styles.content}>
            <div onClick={homepageDisappears} style={{fontSize: "xxx-large"}}>
                <img src={logo} style={{maxWidth:"200px", paddingBottom:"50px"}}  alt='Logo RochExpert'/>
                <img 
                    style={{maxWidth:"400px"}} 
                    src='https://www.laboratoires-rochex.com/wp-content/uploads/logo-laboratoires-rochex.svg' 
                    alt='Logo Laboratoires Rochex' />
            </div>
            <p>(Application en cours de développement)</p>
        </div>        
    )
    } else {
        return (
                <Homepage/>
        )
    }
}

