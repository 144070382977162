// Brought the left_arrow_icon import from productdetails.js - should I factorize that elsewhere?
import left_arrow_icon from './icons8-left-arrow-50.png';
import ProductDetails from "./productdetails";
import React, { useState } from "react";
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

function ProductCard({ product, onClick }) {

    const handleClick = () => {
        onClick(product);
    }

    const productCardStyles = {
        card: {
            backgroundColor: "#f3f3f3ff",
            display: "flex",
            flexDirection: "row",
            borderRadius: "10px",
            marginTop: "10px",
            padding: "8px",
        },
        cardImageDiv: {
            flex: "1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        cardImage: {
            maxWidth: "200px"
        },
        cardContent: {
            flex: "2"
        },
        cardTitle: {
            textAlign: "center",
            textTransform: "uppercase"
        },
        cardText: {
            textAlign: "left"
        }
    }

    return (
        <div style={productCardStyles.card} onClick={handleClick}>
            <div style={productCardStyles.cardImageDiv}>
                <img src={product.imageUrl} style={productCardStyles.cardImage} alt={product.name} />
            </div>
            <div style={productCardStyles.cardContent}>
                <h3 style={productCardStyles.cardTitle}>{product.name}</h3>
                <ul style={productCardStyles.cardText}>
                    <li>{product.designation}</li>
                    <li>pH : {product.ph}</li> 
                    {product.ecolabel && <li>Ecolabel</li>}
                    {product.ecocert && <li>Ecocert</li>}
                    {product.safe && <li>Safe 
                            <Tooltip title="Les produits SAFE sont non étiquetés, sans CMR et sans étiquetage TMD. Les produits SAFE+ présentent les mêmes avantages que les produits SAFE et sont, en plus, sans VLEP." enterTouchDelay={0} arrow followCursor>
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </li>}
                    {product.safePlus && <li>Safe+
                            <Tooltip title="Les produits SAFE sont non étiquetés, sans CMR et sans étiquetage TMD. Les produits SAFE+ présentent les mêmes avantages que les produits SAFE et sont, en plus, sans VLEP." enterTouchDelay={0} arrow followCursor>
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </li>}
                </ul>
            </div>
        </div>
    )
}

export default function ProductList({ fakeProductData, propOnClickBis }) {
    const [viewToDisplay, setViewToDisplay] = useState("list");

    const [selectedProduct, setSelectedProduct] = useState([]);

    const handleChange = (newProduct) => {
        setSelectedProduct(newProduct);
        setViewToDisplay("details");
    }

    const handleGoBack = () => {
        setViewToDisplay("list");
    }

    const productListStyles = {
        main: {
            marginBottom: "20px",
            wordWrap: "break-word"
        }
    }
    
    // test
    const handleClickBis = () => {
        propOnClickBis();
    }

    // Got the below styles const for goBackToMenu and leftArrowIcon from productdetails.js - should I factorize that elsewhere? Also not sure where this should live inside the file.
    const styles = {
        goBackToMenu: {
            display: "flex",
            flexDirection: "row"
        },
        leftArrowIcon: {
            marginRight: "12px"
        }
    }

    if (viewToDisplay === "list") {
        if (fakeProductData.length > 0) {
            return (
                <div style={productListStyles.main}>
                    <div onClick={handleClickBis} style={styles.goBackToMenu}>
                        <img src={left_arrow_icon} style={styles.leftArrowIcon} alt='Flèche Gauche Retour au Menu'/>
                        <p>Retour à mes critères de recherche</p>
                    </div>
                    <p>Les Laboratoires Rochex vous recommandent :</p>
                    {fakeProductData.map(product => (
                        <ProductCard
                            product={product}
                            key={product.name}
                            onClick={handleChange}
                        />
                    ))}
                </div>
            );
        }
        else {
            return (
                // Should I factorize the go back mechanism?
                <div style={productListStyles.main}>
                    <div onClick={handleClickBis} style={styles.goBackToMenu}>
                        <img src={left_arrow_icon} style={styles.leftArrowIcon} alt='Flèche Gauche Retour au Menu'/>
                        <p>Retour à mes critères de recherche</p>
                    </div>
                    <p>Rapprochez-vous de votre représentant de vente pour une analyse approfondie de votre besoin.</p>
                </div>
            );
        }
    } else {
        return (
            <ProductDetails
                selectedProduct={selectedProduct}
                propOnClick={handleGoBack}
            />
        )
    }
}